










import { Vue, Component, Prop } from "vue-property-decorator";

@Component
export default class Breadcrumbs extends Vue {
  @Prop() items!: [];
}
